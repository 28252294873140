<template>
  <MainHeader v-if="windowWidth > 420">
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">
            Pipeline Value
            {{ formatPipelineValue(activePipeline.projected_value) }}
          </div>
          <div class="current-date">{{ getPipelineDate() }}</div>
        </div>
        <!-- <div class="vertical-divider"></div> -->
      </div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <UndoRedoBtns
          :isUndoStackActive="isUndoStackActive"
          :isRedoStackActive="isRedoStackActive"
          @undo="Undo()"
          @redo="Redo()"
        />
        <div class="goal-values">
          <PipelineGoalField
            :value="activePipeline.goal_one"
            :placeholder="'Monthly Revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_one: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_two"
            :placeholder="'Confidence Level %'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_two: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_three"
            :placeholder="'Non-revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_three: $event })
            "
          />
        </div>
        <MoreActionsBtn
          :width="'44px'"
          :height="'46px'"
          :class="{ 'active-more-action-btn': actionActive }"
          :isActive="isActionMenuShown"
          @click="openHeaderActionMenu"
        />
        <ReportsButton
          :width="'44px'"
          :height="'46px'"
          :class="{ 'inactive-report': !isSubscriptionActive }"
          @click="getReportsForActivePipeline"
        />
        <ActionMenu
          :width="'280px'"
          :height="'49px'"
          :top="'50px'"
          :left="'345px'"
          :isActionMenuShown="isActionMenuShown"
          @closeActionMenu="closeHeaderActionMenu"
        >
          <ActionMenuItem
            v-for="(action, index) in Object.values(topMenuActions)"
            :key="index"
            :text="action"
            @actionMenuItemClick="handleActionMenuClick"
          />
        </ActionMenu>
      </div>
      <div class="right-side-actions">
        <ActionButton
          :width="'116px'"
          :height="'46px'"
          :text="'Import'"
          :class="{ 'import-btn': true }"
          @click="isImportModalShown = true"
        />
        <div class="buttons-divider"></div>
        <ActionButton
          :width="'165px'"
          :height="'46px'"
          :text="'+ Add new prospect'"
          :class="{
            'inactive-btn': !isSubscriptionActive,
            'add-customer-btn': true,
          }"
          @click="showModal"
        />
      </div>
      <ProspectsActionModal
        :isModalShown="isModalShown"
        :isModalLoading="isModalLoading"
        :modalError="modalError"
        :newProspectName="newProspectName"
        :newProspectCompany="newProspectCompany"
        :newProspectStatus="newProspectStatus"
        @hideModal="hideModal"
        @setNewProspectName="setNewProspectName"
        @setNewProspectCompany="setNewProspectCompany"
        @setNewProspectStatus="setNewProspectStatus"
        @setNewDealValue="setNewDealValue"
        @createProspect="handleCreateProspectAndDeal"
      />
    </div>
  </MainHeader>
  <ProspectsPipelineMobileHeaderRow
    v-if="windowWidth <= 420"
    :selectedRows="selectedRows"
    :isSubscriptionActive="isSubscriptionActive"
    @openSidebar="$emit('openSidebar')"
    @showImportModal="isImportModalShown = true"
  />
  <ImportModal
    :isModalShown="isImportModalShown"
    :isModalLoading="isModalLoading"
    :importedFileName="csvFile?.name"
    :modalMessage="modalMessage"
    :modalError="modalError"
    @setCsvFile="setCsvFile"
    @importItems="submitProspectsImport"
    @hideModal="closeImportModal"
  />
  <HotDealsCard
    :selectedRows="selectedRows.hot"
    :isSubscriptionActive="isSubscriptionActive"
    :shouldUpdateHotList="shouldUpdateHotList"
    :shouldResetHotOrder="shouldResetHotOrder"
    @selectAllDeals="handleAllDealsSelection('hot', $event)"
    @removeAllDealsSelection="handleAllSelectionsRemovalOfAType('hot')"
    @selectOneDeal="handleOneDealSelection('hot', $event)"
    @removeOneDealSelection="handleOneSelectionRemoval('hot', $event)"
    @hotListUpdated="shouldUpdateHotList = false"
    @hotOrderResetted="shouldResetHotOrder = false"
    @updateProspectsList="shouldUpdateProspectsList = true"
  />
  <InProgressDealsCard
    :selectedRows="selectedRows.inProgress"
    :isSubscriptionActive="isSubscriptionActive"
    :shouldUpdateInProgressList="shouldUpdateInProgressList"
    :shouldResetInProgressOrder="shouldResetInProgressOrder"
    @selectAllDeals="handleAllDealsSelection('inProgress', $event)"
    @removeAllDealsSelection="handleAllSelectionsRemovalOfAType('inProgress')"
    @selectOneDeal="handleOneDealSelection('inProgress', $event)"
    @removeOneDealSelection="handleOneSelectionRemoval('inProgress', $event)"
    @inProgressListUpdated="shouldUpdateInProgressList = false"
    @inProgressOrderResetted="shouldResetInProgressOrder = false"
    @updateProspectsList="shouldUpdateProspectsList = true"
  />
  <TargetDealsCard
    :selectedRows="selectedRows.target"
    :isSubscriptionActive="isSubscriptionActive"
    :shouldUpdateTargetList="shouldUpdateTargetList"
    :shouldResetTargetOrder="shouldResetTargetOrder"
    @selectAllDeals="handleAllDealsSelection('target', $event)"
    @removeAllDealsSelection="handleAllSelectionsRemovalOfAType('target')"
    @selectOneDeal="handleOneDealSelection('target', $event)"
    @removeOneDealSelection="handleOneSelectionRemoval('target', $event)"
    @targetListUpdated="shouldUpdateTargetList = false"
    @targetOrderResetted="shouldResetTargetOrder = false"
    @updateProspectsList="shouldUpdateProspectsList = true"
  />
  <NotInPipelineProspectsCard
    :selectedRows="selectedSubjects"
    :shouldUpdateProspectsList="shouldUpdateProspectsList"
    @selectOneSubject="selectOneSubject"
    @removeOneSubjectSelection="removeOneSubjectSelection"
    @selectAllSubjects="selectAllSubjects"
    @removeAllSubjectsSelection="removeAllSubjectsSelection"
    @prospectsListWasUpdated="shouldUpdateProspectsList = false"
    @updateInProgressList="shouldUpdateInProgressList = true"
    @updateHotList="shouldUpdateHotList = true"
    @updateTargetList="shouldUpdateTargetList = true"
  />
  <div class="spacer"></div>
  <Teleport to="body">
    <div class="scroll_container" v-if="!isScroll">
      <div class="scroll_title">Scroll Down</div>
      <div class="scroll_arrow"></div>
    </div>
  </Teleport>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainHeader from '../../../common/header/MainHeader.vue'
import UndoRedoBtns from '../../../common/header/UndoRedoBtns.vue'
import MoreActionsBtn from '../../../common/MoreActionsBtn.vue'
import UserInfoHeaderSection from '../../../common/header/UserInfoHeaderSection.vue'
import ActionButton from '../../../common/ActionButton.vue'
import ProspectsActionModal from '../../../common/ProspectsActionModal.vue'
import ActionMenu from '../../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../../common/actionMenu/ActionMenuItem.vue'
import PipelineGoalField from '../../../common/mainContent/PipelineGoalField.vue'
import HotDealsCard from './HotDealsCard.vue'
import InProgressDealsCard from './InProgressDealsCard.vue'
import TargetDealsCard from './TargetDealsCard.vue'
import NotInPipelineProspectsCard from './NotInPipelineProspectsCard.vue'
import ProspectsPipelineMobileHeaderRow from '../prospectsPipeline/ProspectsPipelineMobileHeaderRow.vue'
import ReportsButton from '../../../common/ReportsButton.vue'
import ImportModal from '../../../common/ImportModal.vue'

import { getPipelineDate } from '../../../../utils/helpers'
import { DealType } from '../../../../utils/constants'
import {
  ProspectStatus,
  UserSubscriptionStatus,
} from '../../../../utils/constants'

export default {
  components: {
    MainHeader,
    UndoRedoBtns,
    MoreActionsBtn,
    UserInfoHeaderSection,
    ActionButton,
    ProspectsActionModal,
    ActionMenu,
    ActionMenuItem,
    PipelineGoalField,
    HotDealsCard,
    InProgressDealsCard,
    TargetDealsCard,
    NotInPipelineProspectsCard,
    ProspectsPipelineMobileHeaderRow,
    ReportsButton,
    ImportModal,
  },
  emits: ['openSidebar'],
  data() {
    return {
      windowWidth: window.innerWidth,
      dealsType: DealType,
      prospectStatus: ProspectStatus,
      selectedRows: {
        hot: [],
        inProgress: [],
        target: [],
      },
      selectedSubjects: [],
      shouldUpdateHotList: false,
      shouldUpdateInProgressList: false,
      shouldUpdateTargetList: false,
      shouldUpdateProspectsList: false,
      shouldResetHotOrder: false,
      shouldResetInProgressOrder: false,
      shouldResetTargetOrder: false,
      isScroll: false,
      isActionMenuShown: false,
      isModalShown: false,
      isModalLoading: false,
      modalError: '',
      pageSize: 10,
      currentPageNumber: 1,
      searchTerm: '',
      order: '',
      isUserScrolling: false,
      newProspectName: '',
      newProspectCompany: '',
      newProspectStatus: 'Target',
      newDealValue: null,
      actionActive: false,
      topMenuActions: {
        ARCHIVE: 'Archive',
      },
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus.Canceled,
      ],
      isImportModalShown: false,
      csvFile: null,
      modalMessage: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      var mainContent = document.getElementsByClassName('main-content')[0]
      mainContent.addEventListener('scroll', (e) => {
        this.isScroll = true
      })
    })
  },
  async created() {
    try {
      await this.getActivePipeline()
    } catch (error) {
      console.log('==== error while initializing prospects pipeline: ', error)
    }
  },
  computed: {
    ...mapState([
      'currentUser',
      'activePipeline',
      'isUndoStack',
      'isRedoStack',
      'hotDeals',
      'inProgressDeals',
      'targetDeals',
    ]),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
    isUndoStackActive() {
      return this.isUndoStack
    },
    isRedoStackActive() {
      return this.isRedoStack
    },
  },
  methods: {
    ...mapActions([
      'getActivePipeline',
      'getNextHotDealsPage',
      'getNextTargetDealsPage',
      'getNextInProgressDealsPage',
      'updatePipelineGoals',
      'createProspectAndDeal',
      'removeHotDeals',
      'removeInProgressDeals',
      'removeTargetDeals',
      'clearAllProspectDeals',
      'getMonthReportHtml',
      'handelUndoRedoClick',
      'archiveProspects',
      'importCustomers',
    ]),
    getPipelineDate,
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
    async handlePipelineGoalsUpdate(goalUpdate) {
      const goals = {
        goal_one: this.activePipeline.goal_one,
        goal_two: this.activePipeline.goal_two,
        goal_three: this.activePipeline.goal_three,
      }
      const payload = { ...goals, ...goalUpdate }

      await this.updatePipelineGoals(payload)
    },
    async Undo() {
      await this.handelUndoRedoClick('undoStack')
      await this.clearAllProspectDeals()

      let payload = {
        order: this.order,
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
        pageNumber: this.currentPageNumber,
        dealsType: '',
      }

      payload.dealsType = this.dealsType.HotProspectDeal
      await this.getNextHotDealsPage(payload)

      payload.dealsType = this.dealsType.TargetProspectDeal
      await this.getNextTargetDealsPage(payload)

      payload.dealsType = this.dealsType.InProgressProspectDeal
      await this.getNextInProgressDealsPage(payload)
    },
    async Redo() {
      await this.handelUndoRedoClick('redoStack')
      await this.clearAllProspectDeals()

      let payload = {
        order: this.order,
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
        pageNumber: this.currentPageNumber,
        dealsType: '',
      }

      payload.dealsType = this.dealsType.HotProspectDeal
      await this.getNextHotDealsPage(payload)

      payload.dealsType = this.dealsType.TargetProspectDeal
      await this.getNextTargetDealsPage(payload)

      payload.dealsType = this.dealsType.InProgressProspectDeal
      await this.getNextInProgressDealsPage(payload)
    },
    getReportsForActivePipeline() {
      if (!this.isSubscriptionActive) return
      this.getMonthReportHtml(this.activePipeline.id)
    },
    openHeaderActionMenu() {
      if (!this.isSubscriptionActive) return
      this.isActionMenuShown = true
    },
    closeHeaderActionMenu() {
      this.isActionMenuShown = false
    },
    showModal() {
      if (!this.isSubscriptionActive) return
      this.isModalShown = true
    },
    hideModal() {
      this.newProspectName = ''
      this.newProspectCompany = ''
      this.newProspectStatus = 'Target'
      this.newDealValue = null
      this.modalError = ''
      this.isModalShown = false
    },
    setNewProspectName(newProspectName) {
      this.newProspectName = newProspectName
    },
    setNewProspectCompany(newProspectCompany) {
      this.newProspectCompany = newProspectCompany
    },
    setNewProspectStatus(statusName) {
      this.newProspectStatus = statusName
    },
    setNewDealValue(newDealValue) {
      this.newDealValue = newDealValue
    },
    async handleCreateProspectAndDeal() {
      if (!this.newProspectName || !this.newProspectCompany) {
        this.modalError = 'Prospect name and company are required'
        return
      }

      if (
        this.newProspectStatus !== 'Not in Pipeline' &&
        !Number(this.newDealValue)
      ) {
        this.modalError =
          'Deal value is required if you want to add prospect to the pipeline'
        return
      }

      const payload = {
        new_prospect: {
          name: this.newProspectName,
          company: this.newProspectCompany,
          status: this.prospectStatus[this.newProspectStatus],
        },
        dealValue: this.newDealValue,
      }

      try {
        this.isModalLoading = true

        await this.createProspectAndDeal(payload)

        if (
          this.prospectStatus[this.newProspectStatus] ===
          this.prospectStatus.Hot
        ) {
          this.shouldResetHotOrder = true
        } else if (
          this.prospectStatus[this.newProspectStatus] ===
          this.prospectStatus['In Progress']
        ) {
          this.shouldResetInProgressOrder = true
        } else if (
          this.prospectStatus[this.newProspectStatus] ===
          this.prospectStatus.Target
        ) {
          this.shouldResetTargetOrder = true
        }

        this.isModalShown = false
      } catch (error) {
        console.log('==== error while creating prospect: ', error)
        this.modalError = 'Something went wrong, please try again'
      } finally {
        this.isModalLoading = false
        this.newProspectName = ''
        this.newProspectCompany = ''
        this.newDealValue = null
      }
    },
    setCsvFile(evt) {
      this.csvFile = evt.target.files[0]
    },
    closeImportModal() {
      this.isImportModalShown = false
      this.isModalLoading = false
      this.modalMessage = ''
      this.modalError = ''
      this.csvFile = null
    },
    async submitProspectsImport() {
      this.modalError = ''
      this.modalMessage = ''
      this.isModalLoading = true

      try {
        const message = await this.importCustomers(this.csvFile)

        this.modalMessage = message
        this.shouldUpdateHotList = true
        this.shouldUpdateInProgressList = true
        this.shouldUpdateTargetList = true
        this.shouldUpdateProspectsList = true
      } catch (error) {
        this.modalError = 'Something went wrong. Please, try again'
      } finally {
        this.isModalLoading = false
        this.csvFile = null
      }
    },
    checkIfRowsSelected() {
      const isDealSelected = Object.values(this.selectedRows).find(
        (i) => i.length
      )

      if (!isDealSelected && !this.selectedSubjects.length) return false
      return true
    },
    handleOneDealSelection(dealsType, dealId) {
      this.selectedRows[dealsType].push(dealId)

      if (this.selectedRows[dealsType].length >= 1) {
        this.actionActive = true
      }
    },
    handleAllDealsSelection(dealsType, dealsIds) {
      this.selectedRows[dealsType] = dealsIds
      this.actionActive = true
    },
    handleOneSelectionRemoval(dealsType, dealId) {
      const rowIndex = this.selectedRows[dealsType].indexOf(dealId)

      this.selectedRows[dealsType].splice(rowIndex, 1)

      if (this.selectedRows[dealsType].length < 1) {
        this.actionActive = true
      }
    },
    handleAllSelectionsRemovalOfAType(dealsType) {
      this.selectedRows[dealsType] = []
      this.actionActive = false
    },
    selectAllSubjects(subjectIds) {
      this.selectedSubjects = subjectIds

      if (this.selectedSubjects.length) {
        this.actionActive = true
      }
    },
    removeAllSubjectsSelection() {
      this.selectedSubjects = []

      if (!this.checkIfRowsSelected()) {
        this.actionActive = false
      }
    },
    selectOneSubject(subjectId) {
      this.selectedSubjects.push(subjectId)
      this.actionActive = true
    },
    removeOneSubjectSelection(subjectId) {
      const subjectIndex = this.selectedSubjects.indexOf(subjectId)
      this.selectedSubjects.splice(subjectIndex, 1)

      if (!this.checkIfRowsSelected()) {
        this.actionActive = false
      }
    },
    async handleActionMenuClick() {
      this.isActionMenuShown = false
      this.isLoading = true

      const isDealsIdsLength = Object.values(this.selectedRows).find(
        (i) => i.length
      )

      if (!isDealsIdsLength && !this.selectedSubjects.length) {
        this.isLoading = false
        return
      }

      const { hot, inProgress, target } = this.selectedRows

      try {
        if (hot.length) {
          const filteredHotDeals = this.hotDeals.hotDealsList.filter((i) =>
            hot.includes(i.id)
          )
          const hotSubjectIds = filteredHotDeals.map((i) => i.subject)

          this.selectedSubjects = [...this.selectedSubjects, ...hotSubjectIds]

          await this.removeHotDeals(hot)

          this.shouldUpdateHotList = true
        }

        if (inProgress.length) {
          const filteredInProgressDeals =
            this.inProgressDeals.inProgressDealsList.filter((i) =>
              inProgress.includes(i.id)
            )

          const inProgressSubjectIds = filteredInProgressDeals.map(
            (i) => i.subject
          )

          this.selectedSubjects = [
            ...this.selectedSubjects,
            ...inProgressSubjectIds,
          ]

          await this.removeInProgressDeals(inProgress)

          this.shouldUpdateInProgressList = true
        }

        if (target.length) {
          const filteredTargetDeals = this.targetDeals.targetDealsList.filter(
            (i) => target.includes(i.id)
          )
          const tagretSubjectIds = filteredTargetDeals.map((i) => i.subject)

          this.selectedSubjects = [
            ...this.selectedSubjects,
            ...tagretSubjectIds,
          ]

          await this.removeTargetDeals(target)

          this.shouldUpdateTargetList = true
        }

        this.selectedSubjects.length &&
          (await this.archiveProspects(this.selectedSubjects))

        this.shouldUpdateProspectsList = true

        this.selectedRows.hot = []
        this.selectedRows.inProgress = []
        this.selectedRows.target = []
        this.selectedSubjects = []
      } catch (error) {
        console.log('==== error while removing prospect deals: ', error)
      } finally {
        this.isLoading = false
      }
    },
  },
  async beforeUnmount() {
    sessionStorage.setItem('isScroll', this.isScroll)
    await this.clearAllProspectDeals()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.inactive-report {
  background-image: url('../../../../assets/icons/inactive-report.svg') !important;
}

.inactive-btn {
  background-color: #9c9ea6 !important;
  cursor: not-allowed;
}

.active-more-action-btn {
  background-color: $appActionColor;
  background-image: url('../../../../assets/icons/more-white-icon.svg');
}

.main-header {
  width: calc(100% - 260px);
  height: 180px;
  min-height: 160px;
  padding: 0 60px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  background: #e5e5e5;
}

.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $fontColorBlack;
  overflow-x: scroll;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
      }
    }

    .vertical-divider {
      width: 1px;
      height: 54px;
      margin-left: 20px;
      top: 35px;
      background: #c9cde8;
    }

    .closed-value-container {
      margin-left: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .left-side-actions {
    position: relative;
    display: flex;

    .goal-values {
      position: relative;
      display: flex;
    }
  }

  .right-side-actions {
    margin-left: 10px;
    position: relative;
    display: flex;

    .buttons-divider {
      width: 10px;
    }
  }
}

.spacer {
  width: 100%;
  height: 200px;
  min-height: 200px;
}

@media (max-width: 1024px) {
  .main-header {
    width: 100%;

    .header-row {
      align-items: center;

      .right-side-actions {
        .action-button {
          width: 46px !important;
          background-size: 24px;
          background-position: center;
          background-repeat: no-repeat;
          text-indent: -9999px;
          cursor: pointer;
        }

        .add-customer-btn {
          background-image: url('../../../../assets/icons/add-icon.svg');
        }

        .import-btn {
          background-image: url('../../../../assets/icons/import-icon-white.svg');
        }
      }

      .sidebar-btn {
        position: relative;
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }

      .action-button {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 420px) {
  .main-header {
    width: 500px;
  }

  .spacer {
    min-height: 100px;
  }

  .main-header {
    height: 265px;
  }

  .action-button {
    width: 78px !important;
    font-size: $fontSizeMinified;
  }
}
</style>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.scroll_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  z-index: 99999 !important;
  right: 1px;
  left: 200px;
  flex-direction: column;
  bottom: 15px;
  cursor: pointer;

  .scroll_title {
    font-size: $fontSizeStandart;
    font-family: Rubik, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .scroll_arrow {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/dropdown-icon.svg');
  }
}

@media (max-width: 420px) {
  .scroll_container {
    left: 45px;
  }
}
</style>
